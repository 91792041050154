import React from 'react';

const skills: { name: string; imageSrc: string; component: React.ReactChild }[] = [
  {
    name: 'Libraries / Frameworks',
    imageSrc: '/images/js.png',
    component: (
      <>
        <li>React</li>
        <li>React Native</li>
        <li>Node / Express</li>
        <li>TypeScript</li>
        <li>Angular</li>
        <li>Polymer</li>
        <li>jQuery</li>
        <li>Redux</li>
        <li>Azure Functions</li>
      </>
    ),
  },
  {
    name: 'Skills',
    imageSrc: '/images/skills.png',
    component: (
      <>
        <li>Tech Leadership</li>
        <li>Application Architecture</li>
        <li>Responsive Design</li>
        <li>Flexbox</li>
        <li>CSS Grid</li>
        <li>Tailwind</li>
        <li>Materialize</li>
        <li>Bootstrap</li>
        <li>Webpack</li>
      </>
    ),
  },
  {
    name: 'Tools',
    imageSrc: '/images/tools.png',
    component: (
      <>
        <li>Azure</li>
        <li>Git / Github</li>
        <li>Bash</li>
        <li>PowerShell</li>
        <li>NGINX</li>
        <li>Heroku</li>
        <li>Linux</li>
        <li>MySQL</li>
        <li>Cosmos DB</li>
      </>
    ),
  },
];

const Skills: React.FC<{}> = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:py-16 lg:px-4">
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div className="max-w-3xl">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">Background</h2>
            <p className="mt-4 text-gray-500">
              I am currently a full-stack engineer working in TypeScript on the front and back end. I am the lead
              developer for our React web application, but I currently spend more than half of my time working on the
              Azure Functions and Express.js APIs.
            </p>
            <p className="mt-4 text-gray-500">
              Most of my recent work has been with <b>React, Node.js and TypeScript</b> - I also have experience with
              Angular(6/7/8), Polymer.js, jQuery, Webpack, Gulp, and a lot of other tools. I have integrated with
              Stripe, MailChimp, Google Maps, Twitter, and more.
            </p>
            <p className="mt-4 text-gray-500">
              On my servers I have set up automated deployment, automated backups, ssl certificates, web servers, and
              databases.
            </p>
            <p className="mt-4 text-gray-500">
              My best accomplishment so far is Official-Typing-Test.com - it gets about 1,000,000 page views per month
              with around 70% of that traffic coming from Google. I programmed the app, designed the site, set up the
              server, then did the SEO and link building.
            </p>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
            {skills.map((incentive) => (
              <div key={incentive.name} className="flex justify-start lg:justify-center">
                <div className="flex-shrink-0">
                  <img className="w-16 h-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-0 ml-6">
                  <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                  <ul className="mt-2 text-sm text-gray-500 list-disc pl-6">{incentive.component}</ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
