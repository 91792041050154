import React from 'react';
import { DesktopComputerIcon, AcademicCapIcon } from '@heroicons/react/outline';

const supportLinks = [
  {
    name: 'Official Typing Test',
    href: 'https://official-typing-test.com',
    thumbnail: '/images/ottThumb.png',
    description:
      "This site gets over 1 million page views per month, with 70%+ of the traffic coming from Google. I also have a SAAS product at this site, and my customers include a couple of California's Workforce Development Board organizations.",
    icon: DesktopComputerIcon,
  },
  {
    name: 'My Sight Words',
    href: 'https://mysightwords.com',
    thumbnail: '/images/sightWordsThumb.png',
    description:
      "A collection of Kindergarten / 1st grade reading games built with Next.js. This one is less popular, and doesn't make any money but it does have hundreds of members.",
    icon: AcademicCapIcon,
  },
];

const Projects: React.FC<{}> = () => {
  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <img className="w-full h-full object-cover" src="/images/code.jpg" alt="" />
          <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Recent Work</h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">Here are a couple of my more popular projects.</p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-2 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-3xl font-extrabold text-gray-900 text-center">{link.name}</h3>
                <a target="_blank" rel="noreferrer" href={link.href}>
                  <img src={link.thumbnail} alt="" className="mx-auto my-4" />
                </a>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={link.href}
                  className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                >
                  Visit Site<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Projects;
